import { Suspense } from 'react';

import { Navigate, Outlet } from 'react-router-dom';

import { Contents, SideBar } from './components';
import AllScreenSpinner from './components/AllScreenSpinner';
import useTokenValidator from './hooks/useTokenValidator';
import { PathNames } from './types/client/url';
import cookieHelper from './utils/cookieHelper';
import { UrlUtil } from './utils/url';

const PrivateRoute = (): JSX.Element => {
  useTokenValidator();

  if (cookieHelper.hasAccessToken()) {
    return (
      <>
        <SideBar />
        <Suspense fallback={<AllScreenSpinner />}>
          <Contents>
            <Outlet />
          </Contents>
        </Suspense>
      </>
    );
  }

  return <Navigate to={UrlUtil.getUrl(PathNames.LOGIN)} />;
};

export default PrivateRoute;
